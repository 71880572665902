import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';

import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';
import {RenderOrEmpty, TabsPanel, UsersSettingsTab} from '../../components';
import DirectoryServicesTab from '../../components/DirectoryServices/DirectoryServicesTab';
import {updateBaseSetting} from './action';

import style from './style';

function PersonasEditForm({classes, isEditMode, getPolicies, domains}) {
  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const baseSettings = useSelector((store) => store.personaEditFormReducer.baseSettingsObject);

  const handleClickTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleSettingsChange = (newSettings) => {
    dispatch(updateBaseSetting(newSettings));
  };

  const tabs = [
    {
      text: I18n.t('users.usersConfigurer.persona.settings.tabName'),
      content: <UsersSettingsTab
        baseSettings={baseSettings}
        handleSettingsChange={handleSettingsChange}
        isEditMode={isEditMode}
      />,
    },
    {
      text: I18n.t('users.usersConfigurer.persona.directoryServices.tabName'),
      content: <DirectoryServicesTab
        domains={domains}
        isEditMode={isEditMode}
      />,
    },
  ];

  useEffect(() => {
    if (baseSettings.id !== "") {
      dispatch(getPolicies(baseSettings.id));
    }
  }, [baseSettings.id]);

  return (
    <GridContainer direction="column" className={classes.grid}>
      <ItemGrid>
        <TabsPanel
          activeTabIndex={activeTabIndex}
          disabled={false}
          handleClickTab={handleClickTab}
          tabs={tabs}
        />
      </ItemGrid>
      <RenderOrEmpty isShow={!!tabs[activeTabIndex]}>
        <ItemGrid>
          {tabs[activeTabIndex]?.content ?? ''}
        </ItemGrid>
      </RenderOrEmpty>
    </GridContainer>
  );
}

PersonasEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  getPolicies: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
});

export default withStyles(style)(PersonasEditForm);
